import { onEffect } from "../core.state.commons"
import {
  APP_LOGOUT,
  APP_TENANT,
  APP_URL,
  SET_LOADER_STATUS,
  clearStorageData,
  getEnvionment,
  replaceTenant,
  runTimeoutForRefreshToken
} from "../forcepoint-platform-coreui"
import { ActionState } from "./core.state"
import * as singleSpa from "single-spa"
import { jwtDecode } from "jwt-decode"

const redirectToLogin = () => {
  const url = (window.location.pathname + window.location.hash).slice(1)
  window.location.href = `/platform/login?page=${url}`
}

const findTenantId = (tenants: any[], key: string) => tenants?.find(a => a.hint.toLowerCase() === key)?.id;

export function CoreEffects() {
  /**
   * To fetch user info
   */
  onEffect(ActionState.FETCH_USER_INFO, (store) => {
    const storeData = store.getState()
    const userInfoUrl = APP_URL + "/platform/userinfo"
    const userToken = storeData.userToken
    fetch(userInfoUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          store.dispatch({ type: APP_LOGOUT })
          store.dispatch({ type: SET_LOADER_STATUS, status: false })
          redirectToLogin()
          return false
        } else {
          const userResponse = { ...res }
          if (userResponse.scope === "") {
            singleSpa.navigateToUrl("/401")
            store.dispatch({ type: SET_LOADER_STATUS, status: false })
          }
          const tenantId = findTenantId(userResponse?.ext?.tenantId, 'sse');
          const gtenantId = findTenantId(userResponse?.ext?.tenantId, 'global');

          const isEmulated = sessionStorage.getItem("isEmulated")
          const isSuperAdmin = gtenantId === "00000000-0000-0000-0000-000000000000";
          const hasEulaSearchScope = userResponse?.scope?.includes("eula.search") || userResponse?.scope?.includes("eula:search");
          const isNotEmulated = isEmulated !== "true";

          if (hasEulaSearchScope && !isSuperAdmin && isNotEmulated) {
            store.dispatch({
              type: ActionState.GET_PENDING_EULAS,
              payload: { userToken: userToken, userResponse }
            })
          } else if ((isSuperAdmin && isNotEmulated) || isEmulated === "true") {
            store.dispatch({ type: ActionState.SET_USER_INFO, userResponse })
            store.dispatch({ type: ActionState.GET_APP_CONFIG })
          } else {
            store.dispatch({ type: ActionState.SET_USER_INFO, userResponse })
            store.dispatch({ type: SET_LOADER_STATUS, status: false })
          }

          if (window.location.hostname !== "localhost") {
            window["walkme_data"].userId = gtenantId
            window["walkme_data"].companyId = tenantId
            window["walkme_data"].walkme_proxyURL = window.location.hostname
            window["walkme_data"].userScopes = userResponse?.scope
          }
          store.dispatch({
            type: ActionState.SET_APPLICATION_MESSAGES,
            language: "en-us"
          })
        }
      })
      .catch((err) => {
        store.dispatch({ type: APP_LOGOUT })
        store.dispatch({ type: SET_LOADER_STATUS, status: false })
        redirectToLogin();
      })
  })

  /**
   * Action to call app.json to get configuration
   */
  onEffect(ActionState.GET_APP_CONFIG, (store) => {
    const storeData = store.getState()
    const globalTenant = storeData?.userInfo?.ext?.tenantId.find(
      (a) => a?.hint === "global"
    )
    const param = APP_TENANT.includes("superadmin") ? '/superadmin-app' : '/app';
    let appJSONUrl = `//localhost:9002/application${param}`;

    if (window.location.hostname !== "localhost") {
      const param = globalTenant.id === "00000000-0000-0000-0000-000000000000" ? '?applicationType=superAdmin' : '';
      appJSONUrl = `${APP_URL}/api/applications${param}`;
    }
    const getAppUrlDomain = () => {
      const domain = (APP_URL?.split(".").slice(-1))?.[0];
      let appTenantVal: string = APP_TENANT.substring(0, APP_TENANT.length - 1);
      if (!APP_URL.endsWith(".com")) {
        appTenantVal = `${APP_TENANT?.substring(0, APP_TENANT?.length - 1)}-${domain}`
      }
      return { appTenantVal, domain }
    }
    const parseAppModules = (modules, appTenantVal, domain) => {
      if (!modules) {
        return [];
      }
      for (const module of modules) {
        module.moduleUrls.prefix = replaceTenant(module.moduleUrls.prefix, appTenantVal, domain);
        module.moduleUrls.apiURL = replaceTenant(module.moduleUrls.apiURL, appTenantVal, domain);
      }
      return modules;
    }
    const userToken = storeData.userToken
    fetch(appJSONUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        const { appTenantVal, domain } = getAppUrlDomain();
        for (const app of res) {
          app.modules = parseAppModules(app.modules, appTenantVal, domain);
          app.sharedModules = parseAppModules(app.sharedModules, appTenantVal, domain);
          if (app.openInIframe && app.iframeURL) {
            app.iframeURL = app.iframeURL
              .replace("{tenantHost}", appTenantVal)
              .replace("OIDC_ACCESS_TOKEN", userToken)
              .replace("ENV", getEnvionment())
              .replace("TENANT", appTenantVal);
          }
        }
        store.dispatch({
          type: ActionState.SET_APPLICATIONS,
          applications: res
        })
      })
      .catch((err) => {
        singleSpa.navigateToUrl("/401")
        store.dispatch({ type: SET_LOADER_STATUS, status: false })
      })
  })

  /**
   * To App logout
   */
  onEffect(ActionState.APP_LOGOUT, (store) => {
    const callLogoutUrl = true
    clearStorageData(callLogoutUrl)
    store.dispatch({ type: ActionState.SET_APP_LOGOUT_SUCCESS })
  })

  /**
   * To auto login user.
   */
  onEffect(ActionState.AUTO_LOGIN, (store) => {
    const cookies = document.cookie.split(";");
    const isValidToken = (token) => token && token !== "null" && token !== "undefined" && token !== "";
    const getCookieValue = (cookieName) => {
      const cookie = cookies.find((a) => a.includes(`${APP_TENANT}cookie.${cookieName}`));
      return cookie?.split("=")?.[1];
    };
    const clearCookie = (key, domain = '') => {
      document.cookie = `${APP_TENANT}cookie.${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${domain}`
    }
    const setCookie = (key, val, expiry = null, domain = '') => {
      const expiryStr = expiry ? `Expires=${expiry};` : '';
      document.cookie = `${APP_TENANT}cookie.${key}=${val}; Path=/; ${expiryStr} Domain=${domain}`
    }

    const getSessionInterval = () => {
      const expiryDate = new Date(decodedUserToken.expiry).getTime()
      const currenTime = new Date().getTime()
      return expiryDate - currenTime
    }

    const authToken = getCookieValue("oidc.auth");
    const idToken = getCookieValue("oidc.id_token");

    const decodedUserToken = isValidToken(authToken) ? JSON.parse(atob(authToken)) : null;
    const decodedIdToken = isValidToken(idToken) ? jwtDecode(idToken) : null;

    const hostname = APP_URL?.split(".")?.splice(1)?.join(".") || null
    if (decodedIdToken) {
      clearCookie('oidc.id_token', hostname)
      setCookie('oidc.id_token', idToken)
      store.dispatch({
        type: ActionState.SET_ID_TOKEN,
        idToken: decodedIdToken,
        idTokenEncoded: idToken
      })
    }
    if (decodedUserToken) {
      clearCookie('oidc.auth', hostname);
      const expiry = new Date(decodedUserToken.expiry).toUTCString()
      setCookie('oidc.auth', authToken, expiry)
    }

    const cookieCheck = sessionStorage.getItem("cookieCheck")
    if (decodedUserToken && cookieCheck) {
      const dateInterval = getSessionInterval();
      window.parent.postMessage(
        {
          message: "NEW_TOKEN_RECEIVED",
          dateInterval: dateInterval,
          decodedUserToken: decodedUserToken.token,
          decodedIdToken: decodedIdToken,
          idTokenEncoded: idToken
        },
        window.location.origin
      )
    }

    const handleUserToken = (decodedUserToken) => {
      const dateInterval = getSessionInterval();
      runTimeoutForRefreshToken(dateInterval);
      store.dispatch({
        type: ActionState.SET_USER_TOKEN,
        userToken: decodedUserToken.token,
      });
      store.dispatch({ type: ActionState.FETCH_USER_INFO });
    };

    const handleNonLocalRedirection = () => {
      if (window.location.pathname !== "/logout") {
        redirectToLogin()
      } else {
        store.dispatch({ type: SET_LOADER_STATUS, status: false });
      }
    };
    const handleRedirection = () => {
      if (window.location.hostname !== "localhost") {
        handleNonLocalRedirection();
      } else {
        singleSpa.navigateToUrl("/local-login");
        store.dispatch({ type: SET_LOADER_STATUS, status: false });
      }
    };

    if (decodedUserToken) {
      handleUserToken(decodedUserToken);
    } else {
      handleRedirection();
    }
  })

  /**
   * To set application messages
   */
  onEffect(ActionState.SET_APPLICATION_MESSAGES, (store, action) => {
    const JSONURL = "/assets/config/resources.en-us.json"
    fetch(JSONURL, {
      method: "GET"
    })
      .then((res) => res.json())
      .then((res) => {
        store.dispatch({
          type: ActionState.SET_APPLICATION_MESSAGES_SUCCESS,
          messages: res
        })
      })
  })

  /**
   * To get Pending EULAs
   */
  onEffect(ActionState.GET_PENDING_EULAS, (store, action) => {
    const url = APP_URL + "/api/eulas/pending"
    const { userToken, userResponse } = action.payload
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          store.dispatch({
            type: ActionState.SET_PENDING_EULAS,
            payload: { pendingEulas: res, token: userToken, userResponse }
          })
        } else if (res === null || res?.length === 0) {
          store.dispatch({
            type: ActionState.SET_PENDING_EULAS,
            payload: null
          })
          if (userResponse) {
            store.dispatch({ type: ActionState.SET_USER_INFO, userResponse })
            store.dispatch({ type: ActionState.GET_APP_CONFIG })
          }
        } else {
          singleSpa.navigateToUrl("/401")
          store.dispatch({ type: SET_LOADER_STATUS, status: false })
        }
      })
      .catch((err) => {
        singleSpa.navigateToUrl("/401")
        store.dispatch({ type: SET_LOADER_STATUS, status: false })
      })
  })
}
