import { createCoreStore } from "./core.state.commons"
import { CoreEffects } from "./state/core.effects"
import { CoreReducer } from "./state/core.reducer"
import { ActionState, initialState } from "./state/core.state"
import { Store } from "redux"

/**
 * Exporting all actions
 */
export const {
  GET_APP_CONFIG,
  AUTO_LOGIN,
  APP_LOGOUT,
  SET_APPLICATIONS,
  SET_ACTIVE_APP,
  SET_USER_TOKEN,
  SET_ID_TOKEN,
  GET_COMPANY_ID,
  FETCH_USER_INFO,
  SET_USER_INFO,
  SET_INTER_APP_DATA,
  SET_LOADER_STATUS,
  SET_PAGE_TITLE,
  SET_ACTIVE_MENU,
  SET_SETTINGS_TYPE,
  SET_APP_TITLE,
  GET_PENDING_EULAS,
  SET_PENDING_EULAS,
  SET_FONE_ALERT_CONFIG,
  SET_IFRAME_URL
} = ActionState

/**
 * Redux store init.
 */
export const coreStore: Store = createCoreStore(
  CoreEffects,
  CoreReducer,
  initialState
)

/**
 * To set APP URL
 */

let APP_URL
if (window.location.hostname !== "localhost") {
  APP_URL = window.location.origin
} else {
  APP_URL = localStorage.getItem("platform-url")
}
// check if emulated
if (sessionStorage.getItem("isEmulated"))
  APP_URL = sessionStorage.getItem("emulatedURL")

export { APP_URL }
/**
 * To set APP Tenant.
 */
export const APP_TENANT = `${APP_URL?.split(".")[0]?.split("//")[1]}.`

export const getFreshToken = (APP_TENANT?: string) => {
  document.cookie = `${APP_TENANT}cookie.oidc.auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${""}`
  const cookies = document.cookie.split(";")
  const idCookie = cookies.find((a) =>
    a.includes(APP_TENANT + "cookie.oidc.id_token")
  )
  document.cookie = `${APP_TENANT}cookie.oidc.id_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${""}`
  const hostname = APP_URL?.split(".")?.splice(1)?.join(".") || null
  const [idKey, idTokenVal] = idCookie?.split("=") || []
  if (idTokenVal !== null && idTokenVal !== undefined && idTokenVal !== "") {
    document.cookie = `${APP_TENANT}cookie.oidc.id_token=${idTokenVal}; Path=/; Domain=${hostname}`
    const iframeElement = document.createElement("iframe")
    iframeElement.classList.add("authIframe")
    iframeElement.style.display = "none"
    iframeElement.src = `${APP_URL}/platform/login?page=${(
      window.location.pathname + window.location.hash
    ).slice(1)}`
    document.body.append(iframeElement)
    sessionStorage.setItem("cookieCheck", "on")
  }
}
/**
 * To run timeout for refresh token.
 * @param timeInterval
 */
export function runTimeoutForRefreshToken(timeInterval) {
  setTimeout(() => {
    getFreshToken()
  }, timeInterval - 120000)
}

/**
 * Replace tenant function.
 * @param prefix
 * @param tenant
 * @returns
 */
export function replaceTenant(prefix, tenant, domain?) {
  if (prefix === "") {
    return ""
  }
  const withTenantUrl = prefix.replace("{tenantHost}", tenant)
  if (withTenantUrl === "") {
    return window.location.protocol + "//" + window.location.hostname
  } else if (withTenantUrl.includes("http")) {
    return withTenantUrl.replace("." + domain, ".com")
  } else {
    return (
      window.location.protocol +
      "//" +
      withTenantUrl.replace("." + domain, ".com")
    )
  }
}

/**
 * To get active enviornment
 */
export const getEnvionment = () => {
  const url = window.location.hostname.split(".")
  if (url.includes("dev")) {
    return "dev"
  } else if (url.includes("qa")) {
    return "qa"
  } else if (url.includes("trial")) {
    return "trial"
  } else {
    return "prod"
  }
}

/**
 * To clear storage data
 * @param callLogoutUrl
 */
export const clearStorageData = (callLogoutUrl) => {
  const isEmulated = sessionStorage.getItem("isEmulated")
  const oldAppTenant = sessionStorage.getItem("oldAppTenant")
  const cookies = document.cookie.split(";")
  const idCookie = cookies.find((a) =>
    a.includes(
      (isEmulated === null ? APP_TENANT : oldAppTenant) + "cookie.oidc.id_token"
    )
  )
  const [idKey, idTokenVal] = idCookie?.split("=") || []
  localStorage.removeItem("platform-url")
  document.cookie = `${APP_TENANT}cookie.oidc.id_token=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Domain=${""}`
  document.cookie = `${APP_TENANT}cookie.oidc.auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${""}`
  sessionStorage.removeItem("iframeUrl")
  if (isEmulated) {
    document.cookie = `${oldAppTenant}cookie.oidc.id_token=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Domain=${""}`
    document.cookie = `${oldAppTenant}cookie.oidc.auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${""}`
    sessionStorage.removeItem("oldAppTenant")
    sessionStorage.removeItem("isEmulated")
    sessionStorage.removeItem("emulatedURL")
    sessionStorage.removeItem("oldCookies")
    sessionStorage.removeItem("oldUserInfo")
    sessionStorage.removeItem("editProfilePrevUrl")
  }
  if (callLogoutUrl) {
    let tokenHint = idTokenVal ?? coreStore.getState()?.idTokenEncoded
    window.location.href = `/platform/logout?id_token_hint=${tokenHint}`
  }
}

/**
 * To show fone alert
 * @param alertData
 */
export const showFoneAlert = (alertData) => {
  coreStore.dispatch({
    type: SET_FONE_ALERT_CONFIG,
    payload: {
      status: true,
      alertProps: alertData
    }
  })
}

/**
 * To close fone alert
 */
export const closeFoneAlert = () => {
  coreStore.dispatch({
    type: SET_FONE_ALERT_CONFIG,
    payload: {
      status: false,
      alertProps: null
    }
  })
}
